<template>
<v-layout>
  <v-data-table
    :headers="headers"
    :items="ArrayLlantas"
     :search="search"
    sort-by="calories"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Cambios de Llantas</v-toolbar-title>

         <v-btn dark color="indigo" class="mx-5" @click="listar()"><v-icon>autorenew</v-icon></v-btn>

        <v-divider class="mx-4" inset  vertical></v-divider>

         <vue-excel-xlsx v-show="data_excel.length >0"
              :data="data_excel"
              :columns="column_excel"
              :filename="'Llantas_log'"
              :sheetname="'hoja1'">
              <v-btn color="info">
                  <v-icon dark>get_app</v-icon>
              </v-btn>
            
              </vue-excel-xlsx>

        
        <v-flex  mx-md-3 mx-lg-4  mx-sm-2 mx-xs-1 mx-1>
                <v-btn dark color="success" @click="dialogInicio=true">
                  <v-icon>date_range</v-icon>
                </v-btn>
            </v-flex>

        <v-spacer></v-spacer>

         <v-text-field class="mx-5"
        v-model="search"
        append-icon="mdi-magnify"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>

        <v-dialog  v-model="dialog"  max-width="650px" >
          <template v-slot:activator="{ on, attrs }">
            <v-btn  color="primary" dark  class="mb-2" v-bind="attrs" v-on="on">
              <v-icon>add</v-icon>
            </v-btn>
          </template>
          <v-card>


           <v-toolbar dark color="indigo">
              <v-toolbar-title>
                 <span class="headline">{{ formTitle }}</span>
              </v-toolbar-title>
                 <v-spacer></v-spacer>
                 <v-icon @click="dialog=false" dark>close</v-icon>
            </v-toolbar>

            <v-card-text>
              <v-container>
                <v-row>


                    <v-col cols="12" sm="6" md="6">
                    <v-select
                            v-model="nombre_flota"
                            :items="FlotasArray"
                            prepend-icon="commute"
                            label="Flota"
                            @change="FiltrarFlotasVehiculos()"
                        ></v-select>
                </v-col>

                  <v-col cols="12" sm="6" md="6">
                        <v-select
                            v-model="editedItem.id_vehiculo_llanta"
                            :items="UnidadesArray"
                            prepend-icon="time_to_leave"
                            label="Vehiculo"
                            :rules="[rules.required]"
                            :error-messages="errorMessages"
                            @change="ActualizarOdomGps()"
                          
                        ></v-select>
                  </v-col>

                  


                   <v-col  cols="12" sm="6" md="4">
                    <v-text-field   v-model="editedItem.no_cambiadas" prepend-icon="tag" label="Llantas cambiadas"></v-text-field>
                  </v-col>

                  <v-col  cols="12" sm="6" md="4">
                    <v-text-field   v-model="editedItem.tipo" prepend-icon="closed_caption" label="Tipo Llantas"></v-text-field>
                  </v-col>

                   <v-col  cols="12" sm="6" md="4">
                    <v-text-field   v-model="editedItem.marca" prepend-icon="class" label="Marca"></v-text-field>
                  </v-col>

                   <v-col  cols="12" sm="6" md="4">
                    <v-text-field   v-model="editedItem.no_factura" prepend-icon="receipt" label="No.factura"></v-text-field>
                  </v-col>

                  <v-col  cols="12" sm="6" md="4">
                    <v-text-field   v-model="editedItem.garantia_meses" prepend-icon="today" label="Meses garantia"></v-text-field>
                  </v-col>

                   <v-col  cols="12" sm="6" md="4">
                    <v-text-field   v-model="editedItem.costo" prepend-icon="paid" label="Costo"></v-text-field>
                  </v-col>


                  <v-col  cols="12" sm="6" md="4">
                    <v-text-field   v-model="editedItem.odom_cambiadas_tablero" prepend-icon="speed" label="Odom tablero"></v-text-field>
                  </v-col>


                  <v-col  cols="12" sm="6" md="4">
                    <v-text-field   v-model="editedItem.odom_cambiadas_gps" prepend-icon="gps_fixed" label="Odom GPS" disabled></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                   

                     <v-menu
                        ref="menu1"
                        v-model="menu1"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                        >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                            v-model="editedItem.fecha_cambiada"
                            label="Fecha cambio."
                            prepend-icon="event_available"
                            v-bind="attrs"
                            v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="editedItem.fecha_cambiada"
                            no-title
                            @input="menu1 = false"
                        ></v-date-picker>
                        </v-menu>

                  </v-col>

                  
            
                 
                  
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
              <v-btn color="success" dark @click="save">Guardar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">Deseas eliminar registro?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
      <v-icon small @click="deleteItem(item)">delete</v-icon>
    </template>

     <template v-slot:[`item.fecha_cambiada`]="{ item }"> 
       <span>{{ConvertirFecha(item.fecha_cambiada)}} </span>
     </template>

    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">Reset</v-btn>
    </template>


  
      <template v-slot:[`item.garantia_meses`]="{ item }"> 
       <span>{{item.garantia_meses}} </span>
       <v-icon v-if="verificarGarantia(item)==true" color="indigo" dark>verified_user</v-icon>
     </template>

 

  </v-data-table>

 <v-dialog v-model="dialogInicio" persistent max-width="280">
      <v-card>
         <v-toolbar flat color="indigo" class="text-center">
        <v-toolbar-title class="white--text  text-center"><strong>Llantas por fechas</strong></v-toolbar-title>
      </v-toolbar>

        <v-card-text>



           <v-flex>
      
          

                    <v-menu
                      ref="menuFecha1"
                      v-model="menuFecha1"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                  
                      offset-y
                      min-width="290px"
                    >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="fecha_inicio"
                              label="Fecha inicio"
                              prepend-icon="event"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="fecha_inicio" locale="es"
                          no-title  @input="menuFecha1 = false" scrollable>
                          </v-date-picker>
                    </v-menu>
              </v-flex>

          <v-flex>
                 <v-menu
                  ref="menuFecha2"
                  v-model="menuFecha2"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
              
                  offset-y
                  min-width="290px"
                >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fecha_fin"
                          label="Fecha fin"
                          prepend-icon="event"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="fecha_fin" locale="es"
                       no-title  @input="menuFecha2 = false" scrollable>
                      </v-date-picker>
                </v-menu>

              </v-flex>

           
                <v-flex justify-center d-flex>
                  
                      <v-btn color="success" dark @click="listar()" style="margin-right:10px;">Generar</v-btn>
                    <v-btn  @click="dialogInicio=false" style="margin-right:10px;">Cancelar</v-btn>

               </v-flex>
   
        </v-card-text>
       
      </v-card>
</v-dialog>


</v-layout>
</template>


<script>

import axios from 'axios';
import { mapMutations, mapState } from "vuex";


  export default {
    data: () => ({
      search:'',
      ArrayLlantas:[],
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'Vehiculo', value: 'id_vehiculo_llanta.nombre' },
        { text: 'Flota', value: 'id_vehiculo_llanta.flota' },
        { text: '#cambiadas', value: 'no_cambiadas' },
        { text: 'Tipo', value: 'tipo' },
        { text: 'Marca', value: 'marca' },
        { text: 'No.factura', value: 'no_factura' },
        { text: 'Garantia meses', value: 'garantia_meses' },
        { text: "Costo", value: "costo" },
        { text: 'odom.tablero', value:'odom_cambiadas_tablero'},
        { text: 'odom.Gps', value:'odom_cambiadas_gps'},
        { text: 'Fecha cambiada', value: 'fecha_cambiada' },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        id_vehiculo_llanta: '',
        no_cambiadas: 0,
        tipo: '',
        marca: '',
        no_factura:'',
        costo:0,
        garantia_meses:'',
        fecha_cambiada:new Date().toISOString().substr(0, 10),
        odom_cambiadas_tablero:0,
        odom_cambiadas_gps:0,


      },
      defaultItem: {
       id_vehiculo_bat: '',
        no_cambiadas: 0,
        tipo: '',
        marca: '',
        no_factura:'',
        costo:0,
        garantia_meses:'',
        fecha_cambiada:new Date().toISOString().substr(0, 10),
        odom_cambiadas_tablero:0,
        odom_cambiadas_gps:0,
      },

      
        dialogInicio:false,

        menuFecha1:false,
        fecha_inicio:new Date().toISOString().substr(0, 10),
        menuFecha2:false,
        fecha_fin:new Date().toISOString().substr(0, 10),
        date: new Date().toISOString().substr(0, 10),


        ArrayVehiculos:[],
        UnidadesArray:[],
        nombre_flota:'',

        menu1:false,
        date: new Date().toISOString().substr(0, 10),
        errorMessages:'',
       rules: {
          numerico:(value)=>value > 0 || "valor incorrecto",
          min3Chars: (value) => value.length >= 3 || "Min. 3 characters",
          required: (value) => !!value || "campo Requerido.",
          email: (value) => {
                              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                              return pattern.test(value) || "Invalid e-mail.";
                           },
       
       },

        data_excel:[],

          column_excel:[ 
                     {label: "Vehiculo",  field: "Vehiculo"},
                     {label: "Flota", field: "Flota"},
                     {label: "#cambiadas", field: "no_cambiadas"},

                     {label: "Tipo",  field: "tipo"},
                     {label: "Marca", field: "marca"},
                     {label: "No_factura", field: "no_factura"},
                     {label: "Meses Garantia", field: "garantia"},
                     {label: "Costo", field: "costo"},

                     {label: 'odom.tablero', field:'odom_cambiadas_tablero'},
                     {label: 'odom.Gps', field:'odom_cambiadas_gps'},
                     {label: "Fecha_cambiadas", field: "fecha_cambiada"},
                    ],

      


    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevas llantas' : 'Edicion llantas'
      },

         ...mapState(['usuario', 'token']),
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.initialize()
    },

    methods: {

          ...mapMutations(['mostrarLoading','ocultarLoading']),



      initialize () {


         let fecha_actual=new Date();
         fecha_actual.setMonth(0);
         fecha_actual.setDate(1);

        this.fecha_inicio=new Date(fecha_actual).toISOString().substr(0, 10);

        this.ArrayLlantas = [];
        this.listar();
        this.listarVehiculos();
      },

      listar(){

                 this.ArrayLlantas=[];

                 this.mostrarLoading({titulo:'Accediendo a datos'});
                let config={headers:{token:this.token}};
                let me=this;
                axios.post('llantas-historico',
                        {
                        'desde':this.fecha_inicio,
                        'hasta':this.fecha_fin,
                        'id_empresa':this.usuario.id_usr_empresa._id
                        },
                    config
                    ).then(function(response){
                        console.log(response);
                        me.ArrayLlantas=response.data;

                         me.ArrayLlantas.map(function(x){

                         me.data_excel.push({ 
                              'Vehiculo':x.id_vehiculo_llanta.nombre,
                              'Flota':x.id_vehiculo_llanta.flota,
                              'no_cambiadas':x.no_cambiadas,
                              'tipo':x.tipo,
                              'marca':x.marca,
                              'no_factura':x.no_factura,
                              'garantia':x.garantia_meses,
                              'costo':x.costo,
                              'fecha_cambiada':me.ConvertirFecha(x.fecha_cambiada)
                              });  


                       });


                        me.ocultarLoading();
                        me.dialogInicio=false;
                    }).catch(function(error){ console.log(error); });

          },

     listarVehiculos(){

                    this.ArrayVehiculos=[];
                    this.FlotasArray=[];

                      this.mostrarLoading({titulo:'Accediendo a datos'});
                     let config={headers:{token:this.token}};
                    let me=this;
                    axios.get(`vehiculos/${this.usuario.id_usr_empresa._id}`,
                        config
                        ).then(function(response){
                            console.log(response);
                            me.ArrayVehiculos=response.data;

                            me.ArrayVehiculos.map(function(x){

                                let encontrado=false;

                                me.FlotasArray.map(function(y){
                                        if(x.flota==y.value){
                                            encontrado=true;
                                        }
                                });

                                //ingresamos registro unico
                                if(encontrado==false){
                                        me.FlotasArray.push({'text':x.flota, 'value':x.flota})
                                }

                            });

                            me.nombre_flota=me.FlotasArray[0].value;
                            me.FiltrarFlotasVehiculos();


                          me.ocultarLoading();
                        }).catch(function(error){ console.log(error); });

      },

      FiltrarFlotasVehiculos(){

          //filtramos solo por la flota seleccionada
            let me=this;
                me.UnidadesArray=[];
            this.ArrayVehiculos.map(function(x){
                    if(x.flota==me.nombre_flota){
                        me.UnidadesArray.push({text:x.nombre, value:x._id});
                    }
            });

      },

 


      editItem (item) {
        this.editedIndex = this.ArrayLlantas.indexOf(item)
        this.editedItem = Object.assign({}, item)

         this.editedItem.fecha_cambiada =new Date(item.fecha_cambiada).toISOString().substr(0, 10);
         this.nombre_flota=item.id_vehiculo_llanta.flota;
         this.FiltrarFlotasVehiculos();
          this.editedItem.id_vehiculo_llanta=item.id_vehiculo_llanta._id;



        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.ArrayLlantas.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
       // this.ArrayLlantas.splice(this.editedIndex, 1)

           this.mostrarLoading({titulo:'Accediendo a datos'});
          let config={headers:{token:this.token}};
          let id=this.editedItem._id;

                    axios.put(`llantas/${id}`,
                      {'activo':0},
                      config
                        ).then(response=>{
                      
                         console.log(response);
                        
                         this.listar();
                         this.ocultarLoading();

                  }).catch(e=>{
                        console.log(e)
                  });


        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
            //editar registros
         // Object.assign(this.ArrayBaterias[this.editedIndex], this.editedItem)



         if( (this.editedItem.id_vehiculo_llanta!='')&&(this.editedItem.no_cambiadas>0)&&(this.editedItem.tipo!='')&&
         (this.editedItem.no_factura!='')&&(this.editedItem.garantia_meses!='')&&(this.editedItem.costo >0)
          &&(this.editedItem.odom_cambiadas_tablero > 0)){



                   let config={headers:{token:this.token}};
                   this.mostrarLoading({titulo:'actualizando'});
                    let id=this.editedItem._id;

                    axios.put(`llantas/${id}`,
                      { 

                        'id_vehiculo_llanta': this.editedItem.id_vehiculo_llanta,
                        'no_cambiadas': this.editedItem.no_cambiadas,
                        'tipo': this.editedItem.tipo,
                        'marca': this.editedItem.marca,
                        'no_factura':this.editedItem.no_factura,
                        'garantia_meses':this.editedItem.garantia_meses,
                        'costo':this.editedItem.costo,
                        'odom_cambiadas_tablero':this.editedItem.odom_cambiadas_tablero,
                        'odom_cambiadas_gps':this.editedItem.odom_cambiadas_gps,
                        'fecha_cambiada':new Date(this.editedItem.fecha_cambiada),
                       },
                      config

                        ).then(response=>{
                      
                         console.log(response);
                        
                         this.listar();
                         this.ocultarLoading();
                        this.close();

                  }).catch(e=>{
                        console.log(e)
                  });


        }else{
          alert('faltan campos');
        }




        } else {

            //guardar datos
         // this.ArrayBaterias.push(this.editedItem)


      if( (this.editedItem.id_vehiculo_llanta!='')&&(this.editedItem.no_cambiadas>0)&&(this.editedItem.tipo!='')&&
         (this.editedItem.no_factura!='')&&(this.editedItem.garantia_meses!='')&&(this.editedItem.costo >0)
          &&(this.editedItem.odom_cambiadas_tablero > 0)){

                   let config={headers:{token:this.token}};
                   this.mostrarLoading({titulo:'actualizando'});
                    let id=this.editedItem._id;

                    axios.post('nueva-llanta',
                      { 

                        'id_vehiculo_llanta': this.editedItem.id_vehiculo_llanta,
                        'no_cambiadas': this.editedItem.no_cambiadas,
                        'tipo': this.editedItem.tipo,
                        'marca': this.editedItem.marca,
                        'no_factura':this.editedItem.no_factura,
                        'garantia_meses':this.editedItem.garantia_meses,
                        'fecha_cambiada':new Date(this.editedItem.fecha_cambiada),
                        'costo':this.editedItem.costo,
                        'odom_cambiadas_tablero':this.editedItem.odom_cambiadas_tablero,
                        'odom_cambiadas_gps':this.editedItem.odom_cambiadas_gps,
                        'id_empresa_llanta':this.usuario.id_usr_empresa._id
                       },
                      config

                        ).then(response=>{
                      
                         console.log(response);
                        
                         this.listar();
                         this.ocultarLoading();
                        this.close();

                  }).catch(e=>{
                        console.log(e)
                  });

                    }else{
                      alert('faltan campos');
                    }


        }
       
      },

          ConvertirFecha(fecha_parametro){
          let texto=new Date(fecha_parametro).toLocaleDateString()+" "+new Date(fecha_parametro).toLocaleTimeString();
          return(texto);

      },


      ActualizarOdomGps(){

        let me=this;

         this.ArrayVehiculos.map(function(x){
                    if(x._id==me.editedItem.id_vehiculo_llanta){

                          if(x.tipo_odo_activo=='Hardware'){
                              me.editedItem.odom_cambiadas_gps=x.odometro_hw;
                        } else if(x.tipo_odo_activo=='Software'){
                            me.editedItem.odom_cambiadas_gps=x.odometro_sw;
                        }

                    }
            });


       

      },


      
   verificarGarantia(item){

        let fecha_i=new Date(item.fecha_cambiada);
           let dia=fecha_i.getDate();

        let fecha_limit;
        let status_garantia=false;    


        let fechas_garantia=this.calculoGarantia(dia,item.garantia_meses);
        if(fechas_garantia.length >0){
          fecha_limit=new Date(fechas_garantia[fechas_garantia.length-1]);
          if(fecha_limit > fecha_i){status_garantia=true};
        }

        return status_garantia;

    },


    calculoGarantia(numero_dia,limite_meses){


    let fecha_hoy=new Date();
    fecha_hoy.setDate(numero_dia);
    let mes_actual=fecha_hoy.getMonth();
    let mes_nuevo=mes_actual;

    let ArrayMensuales=[];

    let nueva_f_uno=new Date();

    let x=0;
    while(x < limite_meses){

    nueva_f_uno.setDate(numero_dia);
    nueva_f_uno.setMonth(mes_nuevo);

    if(nueva_f_uno > fecha_hoy){
    let estado_fecha=this.validarFecha (nueva_f_uno.getDate(), nueva_f_uno.getMonth()+1, nueva_f_uno.getFullYear() );


      if(estado_fecha){

              if(nueva_f_uno.getDate()==numero_dia){
                  ArrayMensuales.push(new Date(nueva_f_uno));
                //  console.log('nueva fecha pagar:'+nueva_f_uno);
                x++;
              }else{
                console.log('debemos calulcar fecha que no existe')
                let dias_adicionales=nueva_f_uno.getDate();
                nueva_f_uno.setDate(nueva_f_uno.getDate() - dias_adicionales);

                  ArrayMensuales.push(new Date(nueva_f_uno));
                //  console.log('nueva fecha pagar:'+nueva_f_uno);
                  x++;
                  nueva_f_uno.setDate(nueva_f_uno.getDate() + dias_adicionales);


              }
            
        }else{
            console.log('<br>fecha invalida:'+nueva_f_uno);


        }
    }


    if(mes_nuevo==11){
        nueva_f_uno.setMonth(0);
        nueva_f_uno.setFullYear(nueva_f_uno.getFullYear()+1);
        mes_nuevo=nueva_f_uno.getMonth();
        //  console.log('<br>---nuevo anio:'+nueva_f_uno.getFullYear());
    }else{

      mes_nuevo=this.sumarMes(nueva_f_uno, 1);  
     // console.log('<br>-------------------------------');
       // console.log('<br>nuevo mes:'+mes_nuevo);

    }


    }

    return ArrayMensuales;

    },//fin de funcion mensual

    sumarMes(fecha, mes){
   let mas_mes=fecha.getMonth() + mes;
   return mas_mes;
    },

  validarFecha ( d, m, a )
{
    var ok = true;
    if( (a < 1900) || (a > 2050) || (m < 1) || (m > 12) || (d < 1) || (d > 31) )
      ok = false;
    else
    {
      if((a%4 != 0) && (m == 2) && (d > 28))
          ok = false;
      else
      {
          if( (((m == 4) || (m == 6) || (m == 9) || (m==11)) && (d>30)) || ((m==2) && (d>29)) )
            ok = false;
      }
    }
    return ok;
    },
      
    },
  }
</script>